/**
 * Subnavigatie
**/
.second-navigation{
	background-color: $primary-color;
	height: 115px;

	@include media-breakpoint-down(sm){
		height: 50px;
	}

	.current-menu-item{
		position: relative;

		&:after{
			position: absolute;
		}
	}

	ul{ 
		text-transform: uppercase;
		font-size: 18px;
		margin-top: 30px;
		text-align: right;
		
		li{
			display: inline-block;
			text-align: center;
			margin-right: 90px;
			position: relative;
			z-index: 1;
	
			&:last-of-type{
				margin-right: 0;
			}

			&.current-menu-item{
				a{
					&:after{
		    			opacity: 100;
		    			transform: scale(6);						
					}
				}
			}
		}

		li a{
			color: $white;
			display: table-cell;
		    vertical-align: middle;
		    height: 54px;
		    position: relative;
			z-index: 3;

			&:after{
				content: '';
				position: absolute;
				height: 6px;
				width: 6px;
				background-color: $secondary-color;
				border-radius: 100%;
				top: calc(50% - 3px);
				left: calc(50% - 3px);
				z-index: -1;
				opacity: 0;
				transition: all .3s ease-in-out;
			}

		    &:hover{
		    	text-decoration: none;

		    	&:after{
		    		opacity: 100;
		    		transform: scale(6);
		    	}
		    }
		}

		@include media-breakpoint-down(lg){
			font-size: 15px;

			li{
				margin-right: 50px;
			}
		}

		@include media-breakpoint-down(md){
			li{
				margin-right: 15px;
			}
		}
	}



	.menu-diensten-container{
		@include media-breakpoint-down(sm){
			display: none;
		}
	}
}

/**
 * Getuigenis
 **/

.testimonial{
	&--service{
		background-color: transparent;
	}
}

.page-services,
.page-sub{
	h1{
		color: $primary-color;
		font-weight: 400;
		margin-bottom: 2rem;

		@include media-breakpoint-up(md){
			font-size: 56px;			
		}
	}
}

.primary-spacer{
	height: 115px;
	background-color: $primary-color;


	@include media-breakpoint-down(sm){
		height: 50px;
	}
}

.page-sub{
	.main-content{
		position: relative;
		
		@include media-breakpoint-up(md){
			&:after{
				content:'';
				position: absolute;
				height: 400px;
				width: 354px;
				background-image: url('images/bg-sub.svg');
				background-repeat: no-repeat;
			    background-size: 100%;
			    bottom: -100px;
			    right: 15%;
			}
		}
	}
}

.sub-footer-image{
	margin-top: 3px;

	@include media-breakpoint-up(lg){
		margin-bottom: 6rem;
	}
}