/**
 * Typography
**/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
	font-family: 'Barlow', sans-serif;
}

p{
	color: $primary-color;
}

h2{
	color: $secondary-color;
	font-weight: 400;
	position: relative;
	margin-bottom: 1.5rem;

	&:before{
		content: '';
		position: absolute;
		width: 12px;
		height: 12px;
		border-radius: 100%;
		background-color: $secondary-color;
		left: calc(50% - 6px);
		top: -15px;

	}

	@include media-breakpoint-up(md){
		font-size: 40px;
	}
}

h6{
	color: $primary-color;
}

ul{
	list-style-type: none;
	padding-left: 0;
}

ul,
li{
	color: $primary-color;
}


/**
 * Buttons
**/

.btn{
	border-radius: 0;

	&--primary{
		border: 1px solid $secondary-color;
		color: $secondary-color;
		font-size: 18px;
		font-weight: 600;
		padding: 5px 40px;
		margin-top: 1.5rem;

		&:hover{
			color: $primary-color;
			border: 1px solid $primary-color;
		}
	}
}