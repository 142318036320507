/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$primary-color: #1f325a;
$secondary-color: #f2cb2a;

$white: #FFFFFF;
$black: #000000;