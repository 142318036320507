form{
	input,
	textarea{
	    width: 100%;
	    margin-bottom: 30px;
	    height: 40px;
	    padding: 15px;
	    background-color: transparent;
	    border: none;
	    background-color: $white;
	    color: $primary-color;
	}

	textarea{
		min-height: 250px
	}

	input[type="submit"]{
		background-color: $secondary-color;
		color: $primary-color;
		width: 150px;
		height: auto;
		padding: 1rem 2rem;
		float: left;
		margin-bottom: 0;
	}
}

.contact-form{
	margin-top: 2rem;
	background-color: $primary-color;
	padding: 4rem;
	position: relative;
	z-index: 10;
}

span.wpcf7-not-valid-tip{
	position: absolute;
    top: 28px;	
}

div.wpcf7-validation-errors{
    border: 2px solid $secondary-color;
    background-color: $secondary-color;	
}