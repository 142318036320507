#nav-main{
	width: 100%;

	@include media-breakpoint-up(lg){
		margin-top: 30px;
    	margin-bottom: 15px;
    }
}

.navbar-light{
	.navbar-nav{
		float: right !important;
		
		a.nav-link{
			color: $primary-color;
			font-size: 19px;
			margin-left: 35px;
			position: relative;
			transition: all .3s ease-in-out;

			&:after{
				content: '';
				position: absolute;
				height: 6px;
				width: 6px;
				background-color: $secondary-color;
				top: -5px;
				border-radius: 100%;
				left: calc(50% - 3px);
				opacity: 0;
				transition: all .3s ease-in-out;
			}

			&:hover{
				color: $secondary-color;
				
				&:after{
					opacity: 1;
				}
			}

			@include media-breakpoint-up(xl){
				margin-left: 75px;
			}
		}

		.current-menu-item{
			a.nav-link{
				color: $secondary-color;

				&:after{
					opacity: 1;
				}			
			}
		}
	}
}

.mobile-only-nav{
	margin-left: 35px;
	margin-top: 10px;

	br{content:' ';}
	br:after{content:' ';}	

	ul li{
		margin-bottom: 5px;	
	}

	ul li a{
		margin-left: 10px;
		color: $primary-color;
	}	
}

/**
 * Mobile only
 **/
@include media-breakpoint-up(lg){
	.mobile-only-nav{
		display: none;
	}
}


@include media-breakpoint-down(md) {
	.navbar-nav.ml-auto{
		float: left !important;
		margin-left: 0 !important;
	}

	#menu-entries{
    	margin-top: 30px;

		display: none !important;
	
		&.show{
			display: inherit !important;
		}
	}
} 

@include media-breakpoint-down(sm) {
	.navbar-brand{
		img{
    		max-width: 170px;			
		}
	}
}

