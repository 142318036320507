/**
 * Services
**/

.services{
	background-color: $primary-color;
	padding-top: 4rem;
	padding-bottom: 4rem;

	&__item{
		margin-bottom: 30px;
		
		img{
			padding-left: 1rem;
			padding-right: 1rem;
		}	
	}

	.item{
		&__inner{
			background-color: $white;
			padding: 20px;
		}	
	}

	.services__image{
		.row{
			height: 100%;
		}

		.image__inner{
			height: calc(100% - 30px);
			background-image: url('images/home-bg.jpg');
			background-size:cover;
			background-position: 40%;
		}
	}

	h2{
		color: $primary-color;
		font-size: 21px;
		margin-top: 15px;
		text-transform: uppercase;
		margin-bottom: 10px;

		&:before{
			display: none;
		}
	}

	img{
		width: 100%;
	}

	a{
		color: $primary-color;
		position: relative;
		margin-right: 15px;
		padding-right: 10px;
		transition: all .3s ease-in-out;

		&:after{
			content: '';
			position: absolute;
			width: 8px;
			height: 8px;
			background-color: $primary-color;
			border-radius: 100%;
			top: 8px;
			right: -5px;
			transition: all .3s ease-in-out;
		}

		&:hover{
			color: $secondary-color;
			text-decoration: none;

			&:after{
				background-color: $secondary-color;
			}
		}
	}
}

/**
 * Intro
**/
.intro{
	&__quote{
		position: relative;
		background: {
			image: url('images/bg-quote.svg');
			repeat: no-repeat;
			position: center;
		}

		h2{
			font-weight: 500;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			margin-bottom: 0;
			width: 100%;

			&:before{
				display: none;
			}

			@include media-breakpoint-up(md){
				font-size: 32px;
			}
		}
	}

	@include media-breakpoint-down(sm){
		h2{
			position: inherit;
		}

		&__quote{
			margin-top: 2rem;
			background-image: none;
		}

		.intro__text{
			margin-top: 4rem;
		}
	}
}

/**
 * Testimonials
**/

.testimonial{
	background-color: #f9f9f9;

	@include media-breakpoint-up(md){
		padding-top: 4rem;
		padding-bottom: 4rem;
		
		&__content{
			position: relative;
			border-top: 1px solid $secondary-color;
			border-bottom: 1px solid $secondary-color;

			&:before{
				content: '';
				height: 1px;
				background-color: $secondary-color;
				width: 100px;
				right: -100px;
				position: absolute;
				top: -1px;
			}

			&:after{
				content: '';
				height: 1px;
				background-color: $secondary-color;
				width: 100px;
				right: -100px;
				position: absolute;
				bottom: -1px;
			}

			.content-inner{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 90%;
			}
		}
	}

	@include media-breakpoint-down(sm){
		padding-top: 4rem;
		padding-bottom: 3rem;
	}

	&__photo{
		border-radius: 100%;
	    overflow: hidden;
	    padding-top: 25%;
	    height: 0;
	    background-size: cover;
	    transform: scale(1.1);

	    @include media-breakpoint-down(sm){
	    	padding-top: 100%;
			transform: scale(0.9);
	    }
	}
}