/** 
 * Classes
**/

.block{
	margin-top: 6rem;
	margin-bottom: 6rem;

	@include media-breakpoint-down(sm){
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
}

img{
	width: 100%;
}