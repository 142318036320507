footer#footer{
	position: relative;
	padding-top: 4rem;
	padding-bottom: 2rem;

	@include media-breakpoint-up(md){
		margin-top: 6rem;
	}

	&:before{
		content: '';
		background-image: url('images/background-footer.svg');
		background-repeat: no-repeat;
		width: 50%;
		position: absolute;
		bottom: 0;
	    height: calc( 100% + 397px);
	    background-position: bottom right;
	}

	ul{
		list-style-type: none;
		padding-left: 0;
	}

	h6{
		font-weight: 700;
	}

	a{
		color: $primary-color;
		position: relative;
		z-index: 3;
		transition: all .3s ease-in-out;
		
		&:after{
			content: '';
			position: absolute;
			width: 0%;
			height: 15px;
			background-color: $primary-color;
			transition: all .3s ease-in-out;
			left: 0;
			bottom: 0;
			z-index: -1;
		}

		&:hover{
			color: $white;
			text-decoration: none;

			&:after{
				width: 100%;
			}
		}
	}	

	.privacy{
		color: $secondary-color;

		a{
			color: $secondary-color;
		}

		@include media-breakpoint-down(sm){
			color: $primary-color;

			a{
				color: $primary-color;
			}
		}
	}

	.menu-diensten-container{
		br{content:' ';}
		br:after{content:' ';}
	}
}